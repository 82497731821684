import { ReactComponent as Visual } from "../../assets/rotate-back-instruction.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Markdown from "../../components/Markdown";
import useOrientation from "../../hooks/useOrientation";
import useStoreScreenView from "../../hooks/useStoreScreenView";
import Button from "../../components/Button";
import usePreviousScreenData from "../../hooks/usePreviousScreenData";

export default function RotateBackInstructionScreen() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const orientation = useOrientation();
  const [showButton, setShowButton] = useState(false);
  const { previousValue, setPreviousValue } = usePreviousScreenData();
  const [wasIntro, setWasIntro] = useState(false);

  useStoreScreenView("rotate_back_instruction");

  const onNavigate = () => {
    if (wasIntro) {
      setPreviousValue("false");
      navigate("/session/6", { replace: true });
    } else {
      navigate("/session/74", { replace: true });
    }
  };

  useEffect(() => {
    setWasIntro(window.location.href.includes("wasIntro"));
    if (orientation === "vertical") {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [i18n.language, navigate, orientation]);

  return (
    <div
      style={showButton ? { flexDirection: "column" } : {}}
      className="min-h-screen p-6 bg-blue flex items-center justify-center"
    >
      <Visual className="w-32" style={{ marginBottom: "1rem" }} />
      <div className={!showButton ? "pl-12" : ""}>
        <h1 className="text-center text-3xl font-bold text-white mb-6 after:border-b after:block after:w-10 after:mx-auto after:pt-8">
          {t("screens:rotate_back_instruction.title")}
        </h1>
        <div className="px-6 text-white text-center text-xl">
          {wasIntro && (
            <Markdown value={t("screens:rotate_back_instructionIntro.text")} />
          )}
          {!wasIntro && (
            <Markdown value={t("screens:rotate_back_instruction.text")} />
          )}
        </div>
        {showButton && (
          <div className="flex justify-center">
            <Button
              variant="quaternary"
              label={t("screens:rotate_back_instruction.button")}
              onClick={() => onNavigate()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
