import axios, { AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API;

export type AnswerValue = string | Record<string, string>;

export type Answer = {
  answer: AnswerValue;
  question_id: string;
  timestamp: number;
}

export function timestamp(): number {
  const date = new Date();
  return date.getTime();
}

/**
 * Make a new anser type object
 */
export function makeAnswer(question_id: string, answer: AnswerValue): Answer {
  return {
    answer,
    question_id,
    timestamp: timestamp(),
  };
}

/**
 * Start a new session.
 */
export async function startSession(session_id: string, respondent_number: string, pincode: string): Promise<AxiosResponse> {
  return await axios.post('/session/start', {
    session_id,
    respondent_number,
    pincode,
    timestamp: timestamp(),
  });
}

/**
 * Finish session
 */
export async function finishSession(session_id: string): Promise<void> {
  return await axios.post('/session/finish', {
    session_id,
  });
}

/**
 * Submit answers
 */
export async function submitAnswers(session_id: string, answers: Array<Answer>): Promise<void> {
  return await axios.post('/session/answer', {
    session_id,
    answers,
  });
}

/**
 * Submit answer
 */
export async function submitAnswer(session_id: string, answer: Answer): Promise<void> {
  return await submitAnswers(session_id, [answer]);
}

/**
 * Get question
 */
// export async function getQuestion(session_id: string, question_id: string): Promise<void> {
//   await axios.get(`/session/question/${session_id}/${question_id}`);
// }

/**
 * Send Report generated automaticaly to targetted email from .env
 */
export async function sendReport(recipient: string, sessionId: string): Promise<AxiosResponse<any>> {
  return await axios.get('/report/GetSessionReport', {
    params: {
      emailReciever: recipient,
      sessionId,
    }
  })
}