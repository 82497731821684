import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Markdown from "../../components/Markdown";

export default function UserAgreementScreen() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  async function handleStartSession() {
    navigate("/session/welcome", { replace: true });
  }

  function getTranslatedUserAgreement() {
    return `<div classname="disclaimer"><h1><b>${t(
      "user_right_agreement.moovd_user_right_agreement.title"
    )}</b></h1><p>${t(
      "user_right_agreement.moovd_user_right_agreement.desc.1"
    )}</p><p>${t(
      "user_right_agreement.moovd_user_right_agreement.desc.2"
    )}</p><h2><b>${t(
      "user_right_agreement.the_main_rule.title"
    )}</b></h2><p>${t(
      "user_right_agreement.the_main_rule.desc.1"
    )}</p><ul><li>${t("user_right_agreement.the_main_rule.list.1")}</li><li>${t(
      "user_right_agreement.the_main_rule.list.2"
    )}</li><li>${t("user_right_agreement.the_main_rule.list.3")}</li><li>${t(
      "user_right_agreement.the_main_rule.list.4"
    )}</li></ul><p>${t(
      "user_right_agreement.the_main_rule.desc.2"
    )}</p><h2><b>${t(
      "user_right_agreement.using_our_product.title"
    )}</b></h2><p>${t(
      "user_right_agreement.using_our_product.desc.1"
    )}</p><p>${t("user_right_agreement.using_our_product.desc.2")}</p><p>${t(
      "user_right_agreement.using_our_product.desc.3"
    )}</p><p>${t("user_right_agreement.using_our_product.desc.4")}</p><p>${t(
      "user_right_agreement.using_our_product.desc.5"
    )}</p><p>${t("user_right_agreement.using_our_product.desc.6")}</p><p>${t(
      "user_right_agreement.using_our_product.desc.7"
    )}</p><h2><b>${t(
      "user_right_agreement.ownership_of_our_product_and_other_matters.title"
    )}</b></h2><p>${t(
      "user_right_agreement.ownership_of_our_product_and_other_matters.desc.1"
    )}</p><p>${t(
      "user_right_agreement.ownership_of_our_product_and_other_matters.desc.2"
    )}</p><h2><b>${t("user_right_agreement.content.title")}</b></h2><p>${t(
      "user_right_agreement.content.desc.1"
    )}</p><ul><li>${t("user_right_agreement.content.list.1")}</li><li>${t(
      "user_right_agreement.content.list.2"
    )}</li><li>${t("user_right_agreement.content.list.3")}</li><li>${t(
      "user_right_agreement.content.list.4"
    )}</li></ul><p>${t("user_right_agreement.content.desc.2")}</p><p>${t(
      "user_right_agreement.content.desc.3"
    )}</p><p>${t("user_right_agreement.content.desc.4")}</p><p><b>
      ${t("user_right_agreement.content.desc.5")}
    </b></p><h2><b>${t("user_right_agreement.updates.title")}</b></h2><p>${t(
      "user_right_agreement.updates.desc.1"
    )}</p><h2><b>${t(
      "user_right_agreement.liability_and_applicable_law.title"
    )}</b></h2><p>${t(
      "user_right_agreement.liability_and_applicable_law.desc.1"
    )}</p><p>${t(
      "user_right_agreement.liability_and_applicable_law.desc.2"
    )}</p><p><b>
      ${t("user_right_agreement.liability_and_applicable_law.desc.3")}
    </b></p><h2><b>${t(
      "user_right_agreement.termination.title"
    )}</b></h2><p>${t("user_right_agreement.termination.desc.1")}</p><h2><b>${t(
      "user_right_agreement.general_provisions.title"
    )}</b></h2><p>${t(
      "user_right_agreement.general_provisions.desc.1"
    )}</p><p>${t("user_right_agreement.general_provisions.desc.2")}</p><p>${t(
      "user_right_agreement.general_provisions.desc.3"
    )}</p></div>
    `;
  }

  return (
    <div className="min-h-screen pt-8 p-6 bg-blue">
      <h1 className="text-center text-2xl font-bold text-white mb-6 after:border-b after:block after:w-10 after:mx-auto after:pt-8">
        {t("screens:user_rights_agreement.title")}
      </h1>
      <div className="p-6 bg-white shadow-xl min-h-screen-card flex flex-col justify-between">
        <div className="flex-grow h-104 scroll-auto overflow-scroll">
          <Markdown value={getTranslatedUserAgreement()} />
        </div>
      </div>
      <div className="flex-none mt-3 flex gap-2 justify-end">
        <Button
          label={t("go_back")}
          variant="quaternary"
          onClick={handleStartSession}
        />
      </div>
    </div>
  );
}
